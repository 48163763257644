<template>
  <section id="grading">
    <SearchBar :placeholder="'Search course'" :value.sync="search" @search="getInstructorGrading()"/>
    <v-data-table
      :headers="grading_tbl"
      :items="grading"
      class="text--center custom-border poppins f14"
      :loading="loading"
      :items-per-page.sync="paginate"
      hide-default-footer>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          class="primary--text text-capitalize"
          :to="{ name: 'Instructor Grading Course', params: { id: item.id, course_name: item.name } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
    
                        
    <FormPagination 
      :pageCount="pageCount" 
      :page="page"
      :paginate="paginate"
      @page="(e) => {page = e, getInstructorGrading()}" 
      @paginate="(e) => {paginate = e, page=1, getInstructorGrading()}"/>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { grading_tbl } from '@/constants/tblheaders/grading'

export default {
  methods: {
    ...mapActions('instructor', ['getGrading']),
    ...mapMutations('instructor', ['gradingMutation']),

    getInstructorGrading(){
      this.loading = true
      this.getGrading({ paginate: Number(this.paginate), page: this.page, search: this.search }).then((res) => {
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
        this.page = res.current_page
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState('instructor', {
      grading: (state) => state.grading,
    }),
  },
  created(){
    this.gradingMutation([])
  },
  mounted(){
    this.gradingMutation([])
    this.getInstructorGrading()
  },
  data: () => ({
    grading_tbl,
    search: '',
    loading: false,
    pageCount: 1,
    paginate: '10',
    page: 1,
  }),

};
</script>

<style></style>

